import React, { useState, useRef, useEffect } from "react";
import { gsap } from "gsap";
import Button from "../../components/Button/Button";
import { Typewriter } from "react-simple-typewriter";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Container from "../../components/Container/Container";
import * as Styles from "./Hero.module.scss";
import HeroVideo from "../../videos/hero.mp4";

gsap.registerPlugin(ScrollTrigger);

const Hero = () => {
  const videoRef = useRef();
  const heroRef = useRef();
  const [isMuted, setIsMuted] = useState(true);
  const handleVideoMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !isMuted;
  };

  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: heroRef.current,
        start: "bottom top",
        onLeave: () => {
          if (videoRef.current !== null) {
            videoRef.current.muted = true;
            setIsMuted(true);
          }
        },
      },
    });
  }, []);

  return (
    <Container cssContainer={Styles.heroContainer}>
      <div className={Styles.sectionsContainer}>
        <section className={Styles.headingWrap}>
          <h2 className={Styles.title}>DePIN for AI Agents and RPC Nodes</h2>
          <p className={Styles.description}>
          Build and Deploy Secure AI Agents on DePIN Infrastructure for 100% Uptime.
          </p>
          <div className={Styles.bottonsContainer}>
            <Button
              type="link"
              link="https://chat.omniatech.io"
              theme="yellow"
              external
              cssButton={Styles.actionButton}
            >
              AI Brain Platform
            </Button>
            <Button
              type="link"
              link="/#public-rpc"
              external={false}
              cssButton={Styles.publicRpcButton}
            >
              Get Public RPC
            </Button>
          </div>
        </section>
        <section className={Styles.heroWrap} ref={heroRef}>
          <div className={Styles.videoWrap}>
            <video
              autoPlay
              muted
              loop
              playsInline
              id="myVideo"
              ref={videoRef}
              poster="/hero-thumbnail.png"
            >
              <source src={HeroVideo} type="video/mp4" />
            </video>
          </div>
          <div className={Styles.contentWrap}>
            <div className={Styles.typewriterWrap}>
              <Typewriter
                words={["Personal data", "Leaks", "Everywhere", "Secure it"]}
                cursor
                cursorStyle={"_"}
                typeSpeed={70}
                deleteSpeed={50}
                delaySpeed={3600}
                loop={true}
              />
            </div>
            <div className={Styles.muteButtonWrap} onClick={handleVideoMute}>
              {isMuted && (
                <svg
                  width="40"
                  height="35"
                  viewBox="0 0 40 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.242188"
                    y="12.4863"
                    width="7.13018"
                    height="10.6814"
                    fill="white"
                  />
                  <path
                    d="M7.37109 12.4639L20.3682 0.705078V34.9489L7.37109 23.1598V12.4639Z"
                    fill="white"
                  />
                  <path
                    d="M25.6191 11.4385L38.397 24.2154"
                    stroke="white"
                    strokeWidth="4"
                  />
                  <path
                    d="M38.3965 11.438L25.6196 24.2158"
                    stroke="white"
                    strokeWidth="4"
                  />
                </svg>
              )}
              {!isMuted && (
                <svg
                  width="42"
                  height="35"
                  viewBox="0 0 42 35"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.242188"
                    y="12.4863"
                    width="7.13018"
                    height="10.6814"
                    fill="white"
                  />
                  <path
                    d="M7.37109 12.4639L20.3682 0.705078V34.9489L7.37109 23.1598V12.4639Z"
                    fill="white"
                  />
                  <path
                    d="M24.8633 32.7254C33.09 32.7254 39.7591 26.0564 39.7591 17.8296C39.7591 9.60292 33.09 2.93384 24.8633 2.93384"
                    stroke="white"
                    strokeWidth="4"
                  />
                  <path
                    d="M24.8633 24.1824C28.3718 24.1824 31.216 21.3382 31.216 17.8297C31.216 14.3212 28.3718 11.4771 24.8633 11.4771"
                    stroke="white"
                    strokeWidth="4"
                  />
                </svg>
              )}
            </div>
          </div>
        </section>
      </div>
    </Container>
  );
};

export default Hero;
