import React, { useState, useEffect } from "react";
import * as Styles from "./Banner.module.scss";
import ChainGPTGray from "../../images/banner/ChainGPTGray.png";
import ChainGPTColor from "../../images/banner/ChainGPTColor.png";
// import CoinListGray from "../../images/banner/CoinListGray.png";
// import CoinListColor from "../../images/banner/CoinListColor.png";
import CoinListBlur from "../../images/banner/CoinListBlur.png";
// import ByBitGray from "../../images/banner/ByBitGray.png";
// import ByBitColor from "../../images/banner/ByBitColor.png";
import ByBitBlur from "../../images/banner/ByBitBlur.png";
// import OKXGray from "../../images/banner/OKXGray.png";
// import OKXColor from "../../images/banner/OKXColor.png";
import OKXBlur from "../../images/banner/OKXBlur.png";
import KucoinGray from "../../images/banner/KucoinGray.png";
import KucoinColor from "../../images/banner/KucoinColor.png";
// import KucoinBlur from "../../images/banner/KucoinBlur.png";
import GateGray from "../../images/banner/GateGray.png";
import GateColor from "../../images/banner/GateColor.png";
// import GateBlur from "../../images/banner/GateBlur.png";
import MEXCGray from "../../images/banner/MEXCGray.png";
import MEXCColor from "../../images/banner/MEXCColor.png";
// import MEXCBlur from "../../images/banner/MEXCBlur.png";
import BitGetGray from "../../images/banner/BitGetGray.png";
import BitGetColor from "../../images/banner/BitGetColor.png";
// import BitGetBlur from "../../images/banner/BitGetBlur.png";
import HTXGray from "../../images/banner/HTXGray.png";
import HTXColor from "../../images/banner/HTXColor.png";
import HTXBlur from "../../images/banner/HTXBlur.png";
import PancakeGray from "../../images/banner/PancakeGray.png";
import PancakeColor from "../../images/banner/PancakeColor.png";
import BinanceBlurred from "../../images/banner/BinanceBlurred.png";
import TransparentCEX from "../../images/banner/CEX.png";
import bannerAnimation from "../../images/banner/bannerAnimation.json";
import LottieAnimation from "../../components/Lottie/Lottie";
const lunchTimestamp = "2024-10-15T11:00:00.000Z";

const Banner = () => {
  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      const baseTimestamp = new Date(lunchTimestamp).getTime();
      const now = new Date().getTime();
      const timeDifference = baseTimestamp - now;

      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor(
        (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
      );
      const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

      setCountdown({ days, hours, minutes, seconds });
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={Styles.container}>
      <div className={Styles.content}>
        <h1>
          <a
            href="https://staking.omniatech.io/"
            target="_blank"
            rel="noreferrer"
          >
            Power your AI Agent with <span>$OMNIA</span>
          </a>
        </h1>
        <h2>
          <p>
            You need <span>$OMNIA</span> to access OMNIA AI tools
          </p>
        </h2>
        <h3>
          <p>(click the logo for trading pair)</p>
        </h3>

        <div className={Styles.partners}>
          {/* First row */}
          <div className={Styles.partnersRow}>
            <div>
              <a
                href="https://www.htx.com.cm/trade/omnia_usdt/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={HTXGray}
                  onMouseOver={(e) => {
                    e.currentTarget.src = HTXColor;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = HTXGray;
                  }}
                  onFocus={(e) => {
                    e.currentTarget.src = HTXColor;
                  }}
                  onBlur={(e) => {
                    e.currentTarget.src = HTXGray;
                  }}
                  alt=""
                />
              </a>
            </div>
            <div className={Styles.pancakeCustom}>
              <a
                href="https://pancakeswap.finance/swap?inputCurrency=0x55d398326f99059fF775485246999027B3197955&outputCurrency=0xB53cc8303943582949b9a23f0556b0f0C41Fec98"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={PancakeGray}
                  onMouseOver={(e) => {
                    e.currentTarget.src = PancakeColor;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = PancakeGray;
                  }}
                  onFocus={(e) => {
                    e.currentTarget.src = PancakeColor;
                  }}
                  onBlur={(e) => {
                    e.currentTarget.src = PancakeGray;
                  }}
                  alt="PancakeSwap"
                />
              </a>
            </div>

            <div>
              {/* <a href="https://www.bybit.com/" target="_blank" rel="noreferrer"> */}
              <a href="#" rel="noreferrer">
                {" "}
                <img
                  src={TransparentCEX}
                  onMouseOver={(e) => {
                    e.currentTarget.src = TransparentCEX;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = TransparentCEX;
                  }}
                  onFocus={(e) => {
                    e.currentTarget.src = TransparentCEX;
                  }}
                  onBlur={(e) => {
                    e.currentTarget.src = TransparentCEX;
                  }}
                  alt=""
                />
              </a>
            </div>
          </div>

          {/* Second row */}
          <div className={Styles.partnersRow}>
            <div>
              <a
                href="https://www.kucoin.com/trade/OMNIA-USDT"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={KucoinGray}
                  onMouseOver={(e) => {
                    e.currentTarget.src = KucoinColor;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = KucoinGray;
                  }}
                  onFocus={(e) => {
                    e.currentTarget.src = KucoinColor;
                  }}
                  onBlur={(e) => {
                    e.currentTarget.src = KucoinGray;
                  }}
                  alt=""
                />
              </a>
            </div>
            <div>
              <a
                href="https://www.gate.io/trade/OMNIA_USDT"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={GateGray}
                  onMouseOver={(e) => {
                    e.currentTarget.src = GateColor;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = GateGray;
                  }}
                  onFocus={(e) => {
                    e.currentTarget.src = GateColor;
                  }}
                  onBlur={(e) => {
                    e.currentTarget.src = GateGray;
                  }}
                  alt=""
                />
              </a>
            </div>
            <div>
              {/* <a href="https://www.okx.com/" target="_blank" rel="noreferrer"> */}
              <a href="#" rel="noreferrer">
                {" "}
                <img
                  src={BinanceBlurred}
                  onMouseOver={(e) => {
                    e.currentTarget.src = BinanceBlurred;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = BinanceBlurred;
                  }}
                  onFocus={(e) => {
                    e.currentTarget.src = BinanceBlurred;
                  }}
                  onBlur={(e) => {
                    e.currentTarget.src = BinanceBlurred;
                  }}
                  alt=""
                />
              </a>
            </div>
          </div>

          {/* Third row */}
          <div className={Styles.partnersRow}>
            <div>
              <a
                href="https://www.bitget.com/spot/OMNIAUSDT"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={BitGetGray}
                  onMouseOver={(e) => {
                    e.currentTarget.src = BitGetColor;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = BitGetGray;
                  }}
                  onFocus={(e) => {
                    e.currentTarget.src = BitGetColor;
                  }}
                  onBlur={(e) => {
                    e.currentTarget.src = BitGetGray;
                  }}
                  alt=""
                />
              </a>
            </div>

            <div>
              <a
                href="https://www.mexc.com/exchange/OMNIA_USDT"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <img
                  src={MEXCGray}
                  onMouseOver={(e) => {
                    e.currentTarget.src = MEXCColor;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = MEXCGray;
                  }}
                  onFocus={(e) => {
                    e.currentTarget.src = MEXCColor;
                  }}
                  onBlur={(e) => {
                    e.currentTarget.src = MEXCGray;
                  }}
                  alt=""
                />
              </a>
            </div>
            <div>
              {/* <a href="https://www.htx.com/" target="_blank" rel="noreferrer"> */}
              <a href="#" rel="noreferrer">
                {" "}
                <img
                  src={TransparentCEX}
                  onMouseOver={(e) => {
                    e.currentTarget.src = TransparentCEX;
                  }}
                  onMouseOut={(e) => {
                    e.currentTarget.src = TransparentCEX;
                  }}
                  onFocus={(e) => {
                    e.currentTarget.src = TransparentCEX;
                  }}
                  onBlur={(e) => {
                    e.currentTarget.src = TransparentCEX;
                  }}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className={Styles.lottieWrap}>
        <LottieAnimation animationJson={bannerAnimation} />
      </div>
    </div>
  );
};

export default Banner;
