import React, { useState, useEffect } from 'react';
import * as styles from './Snow.module.scss';

const COLORS = ['white', '#F0F973', '#3D52D5'];

const Snow = () => {
  const [snowflakes, setSnowflakes] = useState([]);

  useEffect(() => {
    const createSnowflake = () => {
      const id = Math.random().toString(36);
      const left = Math.random() * window.innerWidth;
      const animationDuration = Math.random() * 3 + 2;
      const opacity = Math.random() * 0.6 + 0.4;
      const color = COLORS[Math.floor(Math.random() * COLORS.length)];

      setSnowflakes(prev => [...prev, { id, left, animationDuration, opacity, color }]);

      setTimeout(() => {
        setSnowflakes(prev => prev.filter(flake => flake.id !== id));
      }, 5000);
    };

    // Create fewer initial snowflakes to prevent performance issues
    for(let i = 0; i < 20; i++) {
      setTimeout(createSnowflake, Math.random() * 2000);
    }

    const snowInterval = setInterval(createSnowflake, 300);
    return () => clearInterval(snowInterval);
  }, []);

  return (
    <div className={styles.snowContainer}>
      {snowflakes.map(flake => (
        <div
          key={flake.id}
          className={styles.snowflake}
          style={{
            left: `${flake.left}px`,
            animationDuration: `${flake.animationDuration}s`,
            opacity: flake.opacity,
            color: flake.color,
            transform: `rotate(${Math.random() * 360}deg)`,
          }}
        >
          <svg width="18" height="24" viewBox="0 0 18 24">
            {/* Robot head */}
            <rect x="3" y="0" width="12" height="12" fill={flake.color} rx="3"/>
            {/* Robot eyes */}
            <circle cx="6" cy="6" r="1.5" fill="black"/>
            <circle cx="12" cy="6" r="1.5" fill="black"/>
            {/* Robot body */}
            <rect x="4.5" y="12" width="9" height="7.5" fill={flake.color}/>
            {/* Robot legs */}
            <rect x="4.5" y="19.5" width="3" height="4.5" fill={flake.color}/>
            <rect x="10.5" y="19.5" width="3" height="4.5" fill={flake.color}/>
          </svg>
        </div>
      ))}
    </div>
  );
};

export default Snow; 