import React, { useRef, useState } from "react";
import { Pagination, Autoplay } from "swiper";
import cn from "classnames";
import Community from "../Community/Community";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Swiper, SwiperSlide } from "swiper/react";
import Container from "../../components/Container/Container";
import * as Styles from "./Slides.module.scss";
import "swiper/css";
import "swiper/scss/pagination";
import "swiper/scss/autoplay";
import lottie from "lottie-web";
import AnimationJson from "../../jsons/Main.json";

const Sections = [
  {
    title: "Intelligent RPC with High Availability",
    description:
      "OMNIA’s intelligent RPC, with high availability, leverages LLM to provide a natural language interface to easily understand blockchain activity. Secure RPC ensures your wallet stays protected while interacting with blockchain networks.",
    segment: [69, 134],
  },
  {
    title: "Strong Security and Consumer Protection",
    description:
      "OMNIA embeds strong security policies in your wallet’s RPC, preventing hacks and safeguarding transactions.",
    segment: [181, 265],
  },
  {
    title: "DePIN for Autonomous AI Agents",
    description:
      "OMNIA ensures strong security by embedding robust security policies into the RPC used by your wallet. This prevents hacks, scams, and other malicious attacks, safeguarding your transactions and data integrity.",
    segment: [300, 382],
  },
];

gsap.registerPlugin(ScrollTrigger);

const Slides = () => {
  const [slideHeight, setSlideHeight] = useState(0);
  const slideRef = useRef([]);

  const getHighestSlide = () => {
    const heightArray = [];

    slideRef.current.map((item, i) => {
      return heightArray[i] = item.clientHeight;
    });

    heightArray.sort((a, b) => {
      return b - a;
    });

    return heightArray[0] + "px";
  };

  const initGsapMobile = (swiper) => {
    const lottieImage1 = lottie.loadAnimation({
      container: document.querySelector("#lottie-item2"),
      animationData: AnimationJson,
      renderer: "svg",
      loop: false,
      autoplay: false,
    });

    swiper.on("slideChange", function () {
      const direction =
        swiper.touches.startX > swiper.touches.currentX
          ? "forward"
          : "backward";

      if (direction === "forward") {
        lottieImage1.setDirection(1);
        lottieImage1.playSegments(Sections[swiper.realIndex].segment, true);
      } else if (direction === "backward") {
        lottieImage1.setDirection(-1);
        lottieImage1.playSegments(Sections[swiper.realIndex].segment, true);
      }
    });
  };

  const initGsap = (swiper) => {
    setSlideHeight(getHighestSlide());
    const lottieImage = lottie.loadAnimation({
      container: document.querySelector("#lottie-item"),
      animationData: AnimationJson,
      renderer: "svg",
      loop: false,
      autoplay: false,
    });

    swiper.on("slideChange", function () {
      const direction =
        swiper.touches.startX > swiper.touches.currentX
          ? "forward"
          : "backward";

      if (direction === "forward") {
        lottieImage.setDirection(1);
        lottieImage.playSegments(Sections[swiper.realIndex].segment, true);
      } else if (direction === "backward") {
        lottieImage.setDirection(-1);
        lottieImage.playSegments(Sections[swiper.realIndex].segment, true);
      }
    });
  };

  // const initGsap = (swiper) => {
  //   let animating;
  //   let mm = gsap.matchMedia();
  //   setSlideHeight(getHighestSlide());

  //   const lottieImage = lottie.loadAnimation({
  //     container: document.querySelector("#lottie-item"),
  //     animationData: AnimationJson,
  //     renderer: "svg",
  //     loop: false,
  //     autoplay: false,
  //   });

  //   let intentObserver = ScrollTrigger.observe({
  //     type: "wheel,touch,scroll",
  //     onUp: () => !animating && changeSlide(false, swiper),
  //     onDown: () => !animating && changeSlide(true, swiper),
  //     tolerance: 450,
  //     preventDefault: true,
  //   });

  //   intentObserver.disable();

  //   const changeSlide = (isScrollingDown, swiper) => {
  //     animating = true;
  //     const slidesCount = swiper.slides.length - 1;

  //     if (isScrollingDown) {
  //       swiper.slideNext();
  //       lottieImage.setDirection(1);
  //       animating = false;
  //     } else {
  //       swiper.slidePrev();
  //       lottieImage.setDirection(-1);
  //       animating = false;
  //     }

  //     lottieImage.playSegments(Sections[swiper.realIndex].segment, true);

  //     if (
  //       (swiper.realIndex === slidesCount && isScrollingDown) ||
  //       (swiper.realIndex === 0 && !isScrollingDown)
  //     ) {
  //       animating = false;
  //       intentObserver.disable();
  //       return;
  //     }
  //   };

  //   let tl;

  //   mm.add("(min-width: 1024px)", () => {
  //     tl = ScrollTrigger.create({
  //       trigger: ".pin-section",
  //       start: "top top",
  //       end: "60% 20%",
  //       pin: true,
  //       scrub: true,
  //       onEnter: () => {
  //         if (!intentObserver.isEnabled) {
  //           intentObserver.enable();
  //           lottieImage.playSegments([69, 134], true);
  //         }
  //       },
  //       onEnterBack: () => {
  //         if (!intentObserver.isEnabled) {
  //           intentObserver.enable();
  //         }
  //       },
  //     });

  //     window.nx = tl.end;

  //     swiper.customScrollTrigger = tl;

  //     setTimeout(() => {
  //       tl.refresh();
  //     }, 10);

  //     return () => {
  //       tl = {};
  //     };
  //   });
  // };

  return (
    <div className="pin-section">
      <section className={Styles.sectionWrap} id="memorandum">
        <Container smaller cssContainer={Styles.containerStyle}>
          <div className={"swipe-section"}>
            <div className={Styles.subtitle}>We are proud of</div>
            <div
              style={{
                height: slideHeight,
              }}
            >
              <Swiper
                modules={[Autoplay, Pagination]}
                pagination={{
                  clickable: true,
                }}
                slidesPerView={1}
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true
                }}
                loop={true}
                spaceBetween={30}
                centeredSlides={true}
                direction='vertical'
                className={Styles.swiper}
                // allowTouchMove={false}
                // noSwiping
                onInit={(swiper) => {
                  // setTimeout(() => {
                  initGsap(swiper);
                  // }, 10);
                }}
                onDestroy={(swiper) => {
                  if (swiper.customScrollTrigger !== undefined) {
                    swiper.customScrollTrigger.kill();
                  }
                }}
              >
                {Sections.map((item, index) => {
                  const { title, description } = item;

                  return (
                    <SwiperSlide key={index}>
                      <div
                        className={Styles.itemSlideContent}
                        ref={(el) => (slideRef.current[index] = el)}
                      >
                        <h2 className={Styles.title}>{title}</h2>
                        <p className={Styles.description}>{description}</p>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>

          <div className={Styles.lottieContainer}>
            <div id="lottie-item" />
          </div>
        </Container>
      </section>

      <div className={Styles.mobileSectionWrap}>
        <Container smaller cssContainer={Styles.containerStyle}>
          <div className={Styles.subtitle}>We are proud of</div>
          <Swiper
            modules={[Pagination, Autoplay]}
            className={cn(Styles.swiperMobile, "mobile-slides-swiper")}
            onInit={(swiper) => {
              initGsapMobile(swiper);
            }}
            slidesPerView={1}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            loop={true}
            pagination
          >
            {Sections.map((item, index) => {
              const { title, description } = item;

              return (
                <SwiperSlide key={index}>
                  <div className={Styles.itemSlideContent}>
                    <h2 className={Styles.title}>{title}</h2>
                    <p className={Styles.description}>{description}</p>
                    <div className={Styles.lottiePlaceholder} />
                  </div>
                </SwiperSlide>
              );
            })}
            <div slot="container-end">
              <div className={Styles.lottieContainerMobile}>
                <div id="lottie-item2" />
              </div>
            </div>
          </Swiper>
        </Container>
      </div>
      <Community />
    </div>
  );
};

export default Slides;
