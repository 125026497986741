import * as React from "react";
import MainLayout from "../layouts/MainLayout";
import Hero from "../sections/Hero/Hero";
import Slides from "../sections/Slides/Slides";
import Clients from "../sections/Clients/Clients";
import Clients2 from "../sections/Clients2/Clients2";
import Products from "../sections/Products/Products";
import Roadmap from "../sections/Roadmap/Roadmap";
import News from "../sections/News/News";
import Featured from "../sections/Featured/Featured";
import TeamFull from "../sections/TeamFull/TeamFull";
import PublicRPC from "../sections/PublicRPC/PublicRPC"
import { SEO } from "../components/SEO/seo"
import Banner from "../sections/Banner/Banner"
import Advisors from "../sections/Advisors/Advisors";
import Snow from '../components/Snow/Snow';
const IndexPage = () => {
  return (
    <>
      <Snow />
      <MainLayout pageTitle={"OMNIA Protocol"} headerFixedOnMobile>
        <Banner />
        <Hero />
        <Slides />
        <Clients />
        <Clients2 />
        <Products />
        <PublicRPC />
        <Roadmap />
        <News />
        <Featured />
        <TeamFull />
        <Advisors />
      </MainLayout>
    </>
  );
};

export default IndexPage;

export const Head = () => (
    <SEO />
)
